@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Google+Sans&display=swap');


$bodyfont: 'Inter', sans-serif;
$headingfont: "Google sans", sans-serif;

/*Material Theme Colors*/

$md-darkblue: (
    50 : #e3e9ec,
    100 : #b9c7cf,
    200 : #8ba2b0,
    300 : #5d7c90,
    400 : #3a6078,
    500 : #174460,
    600 : #143e58,
    700 : #11354e,
    800 : #0d2d44,
    900 : #071f33,
    A100 : #6db2ff,
    A200 : #3a97ff,
    A400 : #077cff,
    A700 : #006fec,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);



// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #ffffff;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;
$pure-blue: #007bff;
$dark-magenta: #7030a0;


$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #174460;
$themecolor-alt: #174460;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #174460;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #47A7EE;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7030a0;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$table-header: #f7f7f7;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

$link-color: #104870;
