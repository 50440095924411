/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
 Topbar
*******************/
mat-toolbar{
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
    &.topbar{
        padding: 0px 15px;
    }
    .navbar-header {
        text-align: center;
        .navbar-brand {
            display: flex;
            line-height: 18px;
            align-items: center;
            .dark-logo {
                display: none;
            }
            b {
               margin-right: 5px;
            }
        }
    }
    
    .profile-pic{
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
}
/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}