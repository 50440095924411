@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans&display=swap");
/*Material Theme Colors*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100001;
  background: rgba(255, 255, 255, 0.6);
}

.spinner {
  width: 40px;
  height: 40px;
  top: 35%;
  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1976d2;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. Posts
 *	7. Widgets
 *	8. Custom Templates
 */
/******************* 
Global Styles 
*******************/
* {
  outline: none;
  box-sizing: border-box;
}

body {
  font: 400 14px/20px "Google Sans", sans-serif !important;
  margin: 0;
  overflow-x: hidden;
  color: #67757c;
  font-weight: 300;
  background: #ffffff;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

a:hover,
a:focus,
a {
  text-decoration: none !important;
}

a.link {
  color: #455a64;
  text-decoration: none;
}
a.link:hover, a.link:focus {
  color: #174460;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fc4b6c;
  background-color: #f9e7eb;
  border-radius: 0.25rem;
}

.shadow-none {
  box-shadow: none !important;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.mr-auto {
  margin-right: auto;
}

.rounded {
  border-radius: 4px;
}

/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Google sans", sans-serif !important;
  font-weight: bold !important;
}

h1 {
  line-height: 40px !important;
  font-size: 30px !important;
}

/*h2 {
  line-height: 36px !important;
  font-size: 26px !important;
}*/
/*h3 {
  line-height: 30px !important;
  font-size: 21px !important;
}*/
/*h4 {
  line-height: 22px !important;
  font-size: 18px !important;
}*/
/*h5 {
  line-height: 18px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

h6 {
  line-height: 16px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}*/
html .display-5,
body .display-5 {
  font-size: 3rem;
}
html .display-6,
body .display-6 {
  font-size: 36px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}
html body .db {
  display: block;
}
html body .op-5 {
  opacity: 0.5;
}
html body .op-3 {
  opacity: 0.3;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-flex {
  display: flex;
}

.no-shrink {
  flex-shrink: 0;
}

.oh {
  overflow: hidden;
}

/*******************
Blockquote
*******************/
html body blockquote {
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-left: 5px solid #174460;
  padding: 15px;
  margin: 20px 0;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px;
}
html body .p-10 {
  padding: 10px;
}
html body .p-20 {
  padding: 20px;
}
html body .p-30 {
  padding: 30px;
}
html body .p-l-0 {
  padding-left: 0px;
}
html body .p-l-10 {
  padding-left: 10px;
}
html body .p-l-20 {
  padding-left: 20px;
}
html body .p-r-0 {
  padding-right: 0px;
}
html body .p-r-10 {
  padding-right: 10px;
}
html body .p-r-20 {
  padding-right: 20px;
}
html body .p-r-30 {
  padding-right: 30px;
}
html body .p-r-40 {
  padding-right: 40px;
}
html body .p-t-0 {
  padding-top: 0px;
}
html body .p-t-10 {
  padding-top: 10px;
}
html body .p-t-20 {
  padding-top: 20px;
}
html body .p-t-30 {
  padding-top: 30px;
}
html body .p-b-0 {
  padding-bottom: 0px;
}
html body .p-b-5 {
  padding-bottom: 5px;
}
html body .p-b-10 {
  padding-bottom: 10px;
}
html body .p-b-20 {
  padding-bottom: 20px;
}
html body .p-b-30 {
  padding-bottom: 30px;
}
html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px;
}
html body .m-l-5 {
  margin-left: 5px;
}
html body .m-l-10 {
  margin-left: 10px;
}
html body .m-l-15 {
  margin-left: 15px;
}
html body .m-l-20 {
  margin-left: 20px;
}
html body .m-l-30 {
  margin-left: 30px;
}
html body .m-l-40 {
  margin-left: 40px;
}
html body .m-r-5 {
  margin-right: 5px;
}
html body .m-r-10 {
  margin-right: 10px;
}
html body .m-r-15 {
  margin-right: 15px;
}
html body .m-r-20 {
  margin-right: 20px;
}
html body .m-r-30 {
  margin-right: 30px;
}
html body .m-r-40 {
  margin-right: 40px;
}
html body .m-t-0 {
  margin-top: 0px;
}
html body .m-t-5 {
  margin-top: 5px;
}
html body .m-t-10 {
  margin-top: 10px;
}
html body .m-t-15 {
  margin-top: 15px;
}
html body .m-t-20 {
  margin-top: 20px;
}
html body .m-t-30 {
  margin-top: 30px;
}
html body .m-t-40 {
  margin-top: 40px;
}
html body .m-b-0 {
  margin-bottom: 0px;
}
html body .m-b-5 {
  margin-bottom: 5px;
}
html body .m-b-10 {
  margin-bottom: 10px;
}
html body .m-b-15 {
  margin-bottom: 15px;
}
html body .m-b-20 {
  margin-bottom: 20px;
}
html body .m-b-30 {
  margin-bottom: 30px;
}
html body .m-b-40 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top;
}
html body .vm {
  vertical-align: middle;
}
html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700;
}
html body .font-normal {
  font-weight: normal;
}
html body .font-light {
  font-weight: 300;
}
html body .font-medium {
  font-weight: 500;
}
html body .font-16 {
  font-size: 16px;
}
html body .font-14 {
  font-size: 14px;
}
html body .font-10 {
  font-size: 10px;
}
html body .font-12 {
  font-size: 12px;
}
html body .font-18 {
  font-size: 18px;
}
html body .font-20 {
  font-size: 20px;
}

/*******************
Border
*******************/
html body .b-0 {
  border: none;
}
html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #fc4b6c !important;
}

.text-muted {
  color: #99abb4 !important;
}

.text-warning {
  color: #ffb22b !important;
}

.text-success {
  color: #26c6da !important;
}

.text-info {
  color: #174460 !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

html body .text-blue {
  color: #02bec9;
}
html body .text-purple {
  color: #7030a0;
}
html body .text-primary {
  color: #7460ee;
}
html body .text-megna {
  color: #00897b;
}
html body .text-dark {
  color: #67757c;
}
html body .text-themecolor {
  color: #174460;
}
html body .text-center {
  text-align: center;
}
html body .text-right {
  text-align: right;
}
html body .text-left {
  text-align: left;
}

/*******************
Background Colors
*******************/
.bg-primary {
  background-color: #7460ee !important;
}

.bg-success {
  background-color: #26c6da !important;
}

.bg-info {
  background-color: #174460 !important;
}

.bg-warning {
  background-color: #ffb22b !important;
}

.bg-danger {
  background-color: #fc4b6c !important;
}

html body .bg-megna {
  background-color: #00897b;
}
html body .bg-theme {
  background-color: #174460;
}
html body .bg-inverse {
  background-color: #2f3d4a;
}
html body .bg-purple {
  background-color: #7030a0 !important;
}
html body .bg-light-primary {
  background-color: #f1effd;
}
html body .bg-light-success {
  background-color: #e8fdf8;
}
html body .bg-light-info {
  background-color: #e3f3fd;
}
html body .bg-light-extra {
  background-color: #ebf3f5;
}
html body .bg-light-warning {
  background-color: #fff8ec;
}
html body .bg-light-danger {
  background-color: #f9e7eb;
}
html body .bg-light-inverse {
  background-color: #f6f6f6;
}
html body .bg-light {
  background-color: #f2f4f8;
}
html body .bg-white {
  background-color: #ffffff;
}

.img-circle {
  border-radius: 100%;
}

.no-shadow {
  box-shadow: none;
}

hr {
  border: 0px;
  height: 1px;
  background: rgba(120, 130, 140, 0.13);
}

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #174460;
}
.round img {
  border-radius: 100%;
}

.round.round-info {
  background: #174460;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-lg {
  line-height: 48px !important;
}

/*******************
froms
*******************/
.form-control {
  border: 0px;
  line-height: 25px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
}

.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.25);
}

.mat-form-field {
  width: 100%;
}

/*******************
Mat button
*******************/
.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
  min-width: 75px !important;
  line-height: 30px !important;
  padding: 0 10px !important;
  box-shadow: none !important;
  border-radius: 2px !important;
  margin: 0.15rem 0.25rem !important;
}

/*******************
Mat card
*******************/
.mat-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 15px;
  padding: 0px !important;
  border-radius: 4px;
}
.mat-card .mat-card-content {
  padding: 16px;
  margin-bottom: 0;
}
.mat-card .mat-card-image {
  width: calc(100% + 0px);
  margin: 0 0px 0px 0px;
}
.mat-card .mat-card-header {
  padding: 12px;
  border-radius: 4px 4px 0 0;
}
.mat-card .mat-card-header .mat-card-title {
  margin-bottom: 2px;
  font-size: 20px;
}
.mat-card .mat-card-header .mat-card-subtitle,
.mat-card .mat-card-header .mat-card-subtitle:not(:first-child) {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
}
.mat-card .mat-card-header-text {
  margin: 0;
}
.mat-card .mat-card-header.text-white .mat-card-title {
  color: #ffffff;
}
.mat-card .mat-card-header.text-white .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.mat-card .mat-card-title {
  font-size: 20px;
  margin-bottom: 5px;
}
.mat-card .mat-card-subtitle {
  margin-bottom: 20px;
}
.mat-card .mat-card-actions {
  margin: 0px !important;
  padding: 0 16px 16px 16px !important;
}

/*******************
Grid
*******************/
.row {
  margin: 0 -10px;
}

/*******************
Table
*******************/
.table {
  width: 100%;
  text-align: left;
}

.table-rasponsive {
  overflow: auto;
}
.table-rasponsive .mat-table {
  min-width: 280px;
}
.table-rasponsive .mat-header-row {
  background-color: #f3f1f1;
}
.table-rasponsive .mat-header-row .mat-header-cell {
  white-space: nowrap;
}
.table-rasponsive .mat-cell {
  white-space: nowrap;
}

/*******************
list inline
*******************/
.list-inline {
  margin-left: 0px;
  padding: 0px;
}
.list-inline li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}
.list-inline li:last-child {
  margin-right: 0px;
}

/*******************/
/*Chat widget*/
/*******************/
.message-box ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.message-box ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px;
}
.message-box .message-widget {
  position: relative;
}
.message-box .message-widget a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  display: flex;
  text-decoration: none;
  padding: 9px 15px;
}
.message-box .message-widget a:hover {
  background: #f2f4f8;
}
.message-box .message-widget a:last-child {
  border-bottom: 0px;
}
.message-box .message-widget a div {
  white-space: normal;
}
.message-box .message-widget a .user-img {
  width: 45px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.message-box .message-widget a .user-img img {
  width: 100%;
}
.message-box .message-widget a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 10px;
}
.message-box .message-widget a .user-img .online {
  background: #26c6da;
}
.message-box .message-widget a .user-img .busy {
  background: #fc4b6c;
}
.message-box .message-widget a .user-img .away {
  background: #ffb22b;
}
.message-box .message-widget a .user-img .offline {
  background: #ffb22b;
}
.message-box .message-widget a .mail-contnet {
  display: inline-block;
  width: 73%;
  vertical-align: middle;
}
.message-box .message-widget a .mail-contnet h5 {
  margin: 5px 0px 0;
  color: #212121;
}
.message-box .message-widget a .mail-contnet .mail-desc,
.message-box .message-widget a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #67757c;
  white-space: nowrap;
}

/*******************/
/*Tooltip*/
/*******************/
.mat-tooltip {
  font-size: 0.875rem !important;
  background-color: #174460 !important;
}

/*******************/
/*Snack-bar*/
/*******************/
snack-bar-container.success {
  background-color: #47A7EE;
  color: #ffffff;
}
snack-bar-container.error {
  background-color: #e6294b;
  color: #ffffff;
}

.add-contact {
  position: absolute !important;
  right: 17px;
  top: 57px;
}

.a-link {
  cursor: pointer;
  color: #007bff;
}

.error {
  color: #e6294b;
  cursor: pointer;
}

.retrieve_1 {
  color: #e9ab2e;
  cursor: pointer;
}

.retrieve_2 {
  color: #7030a0;
  cursor: pointer;
}

.custom-dialog {
  width: 60%;
}

.custom-dialog-small {
  width: 30%;
}

.header-height {
  height: 90px;
}

.header-item {
  background-color: #F7F7F7;
  border-radius: 10px;
  width: 100%;
  padding-top: 20px;
}

.content-page {
  background-color: #F7F7F7;
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
  height: 100vh;
}

.content-item {
  width: 100%;
}

.no-image {
  width: 40px;
  height: 40px;
  background: #D3D3D3;
  border-radius: 50%;
}

.button {
  border: 1px solid #C4C0C0;
  padding: 5px 0;
  border-radius: 20px;
  min-width: 70px;
  font-size: 12px;
  background: white;
  color: black;
}
.button.primary {
  background: #47A7EE;
  color: white;
  border: 0;
}
.button.warn {
  background: #E3646E;
  color: white;
  border: 0;
}

vg-playback-button .button {
  background: transparent;
  color: white;
  border: none;
}

.menu-unread {
  display: inline;
  float: right;
  width: 23px;
  height: 23px;
  background: #F80D38;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
}

.expanded .menu-unread {
  position: absolute !important;
  right: 0 !important;
  top: 15px !important;
}

.center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .custom-dialog {
    width: 80%;
  }
}
/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
 Topbar
*******************/
mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
}
mat-toolbar.topbar {
  padding: 0px 15px;
}
mat-toolbar .navbar-header {
  text-align: center;
}
mat-toolbar .navbar-header .navbar-brand {
  display: flex;
  line-height: 18px;
  align-items: center;
}
mat-toolbar .navbar-header .navbar-brand .dark-logo {
  display: none;
}
mat-toolbar .navbar-header .navbar-brand b {
  margin-right: 5px;
}
mat-toolbar .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For all pages
 ============================================================== */
/*******************
 Main container
*******************/
.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  background: #ffffff !important;
}

html .page-wrapper {
  height: calc(100vh - 64px);
}
html .page-wrapper .page-content {
  padding: 0px 15px 15px 15px;
}

.align-items-center {
  align-items: center;
}

.mat-drawer-container {
  background-color: #ffffff !important;
}

/*******************
Chartistt chart css
******************/
.barchrt .ct-series-a .ct-bar {
  stroke: #26c6da;
}
.barchrt .ct-series-b .ct-bar {
  stroke: #174460;
}

.piechart .ct-series-a .ct-slice-donut {
  stroke: #174460;
}
.piechart .ct-series-b .ct-slice-donut {
  stroke: #26c6da;
}
.piechart .ct-series-c .ct-slice-donut {
  stroke: #7030a0;
}
.piechart .ct-series-d .ct-slice-donut {
  stroke: #f2f4f8;
}
.piechart .ct-series-e .ct-slice-donut {
  stroke: #ffb22b;
}

/*******************/
/*social-profile-first*/
/*******************/
.little-profile .pro-img, .little-profile .pro-img:first-child {
  margin-top: -110px;
  margin-bottom: 20px;
}
.little-profile .pro-img img, .little-profile .pro-img:first-child img {
  width: 128px;
  height: 128px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

.button-row button {
  margin-bottom: 5px;
  margin-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.error-show {
  position: absolute;
  width: 100%;
  display: block;
  color: #ff5a77;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}
.error-show span {
  font-weight: 700;
}

.disable-input {
  color: rgba(0, 0, 0, 0.38) !important;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.error-border .mat-form-field-outline {
  border-color: red !important;
}

.pointer {
  cursor: pointer;
}

.btn-blue {
  background-color: #47a7ee !important;
  color: #ffffff !important;
}

.btn-cancle {
  margin-right: 5% !important;
}

.appointment-dropdown .mat-select-arrow-wrapper {
  background-image: url("/assets/images/icons/ic-dropdown.png");
  background-size: 16px;
  width: 16px;
  height: 16px !important;
}
.appointment-dropdown .mat-select-value {
  width: auto !important;
}
.appointment-dropdown .mat-select-arrow {
  opacity: 0;
}
.appointment-dropdown .mat-select-value-text {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.custsom-dropzone ngx-dropzone-remove-badge {
  opacity: 1;
}

.forgot-password .mat-form-field-infix {
  padding: 0.5em 0 0.8em 0;
}

.change-password .mat-form-field-wrapper {
  padding: 0;
}

.c-light-gray {
  color: lightgray;
}

.OT_publisher .OT_mute {
  pointer-events: none;
}

.custom-input .mat-form-field-infix {
  padding: 4px 0px 12px 0px !important;
}
.custom-input .mat-form-field-outline {
  border: 1px solid;
  border-radius: 25px;
}
.custom-input .mat-form-field-outline .error-border {
  border-color: red;
}
.custom-input .mat-form-field-outline-start {
  border: 0 !important;
}
.custom-input .mat-form-field-outline-end {
  border: 0 !important;
}
.custom-input .mat-form-field-appearance-outline {
  border: none;
}
.custom-input .mat-form-field-outline-gap {
  border: none !important;
}

.doctor-page .doctor-content .page-content {
  position: relative;
  font-weight: 900;
  font-family: "Google Sans" !important;
  background: #ffffff;
}

.custom-autocomplete .selected-custom .mat-option-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-autocomplete .selected-custom .mat-option-text .mat-pseudo-checkbox {
  width: 18px;
  height: 18px;
  background-color: transparent;
}
.custom-autocomplete .selected-custom .mat-option-text .mat-pseudo-checkbox::after {
  color: #7460ee;
  width: 14px;
  height: 6px;
  transform-origin: center;
  top: -4.2426406871px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#snav {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}
#snav .mat-list-item:hover {
  background: none;
}
#snav .mat-list-item .mat-list-item-content {
  display: block;
  padding: 0px 17px;
}
#snav .mat-list-item .mat-list-item-content:hover {
  background: none;
}
#snav .mat-list-item .mat-list-item-content:hover > a {
  color: #174460;
}
#snav .mat-list-item a {
  height: 30px;
  padding: 0 10px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  font-size: 12px;
  white-space: nowrap;
  color: #607d8b;
}
#snav .mat-list-item a mat-icon:not(.dd-icon) {
  margin-right: 0;
}
#snav .mat-list-item a mat-icon {
  color: #99abb4;
  height: 20px;
  width: 20px;
  font-size: 20px;
}
#snav .mat-list-item a .dd-icon {
  font-size: 16px;
  width: 16px;
  transition: 0.2s ease-in;
  margin: 5px 0 5px 5px;
  height: 16px;
}
#snav .mat-list-item a:hover {
  text-decoration: none;
}
#snav .mat-list-item.selected > .mat-list-item-content > a {
  background: #174460;
  border-radius: 4px;
  color: #ffffff;
}
#snav .mat-list-item.selected > .mat-list-item-content > a mat-icon {
  color: #ffffff;
}
#snav .mat-list-item.selected > .mat-list-item-content > a .dd-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#snav .mat-list-item.selected .sub-item .mat-list-item-content a.selected {
  background: transparent;
  color: #2f3d4a;
  font-weight: 500;
}

/*******************
use profile section
******************/
.user-profile {
  position: relative;
  background-size: cover;
}
.user-profile .profile-img {
  width: 50px;
  margin-left: 30px;
  padding: 31px 0;
  border-radius: 100%;
}
.user-profile .profile-img::before {
  -webkit-animation: 2.5s blow 0s linear infinite;
  animation: 2.5s blow 0s linear infinite;
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  top: 31px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 0;
}
@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}
@keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}
.user-profile .profile-img img {
  width: 100%;
  border-radius: 100%;
}
.user-profile .profile-text {
  padding: 5px 0px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.user-profile .profile-text > a {
  color: #ffffff !important;
  width: 100%;
  padding: 6px 30px;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  white-space: nowrap;
}
.user-profile .profile-text > a:after {
  position: absolute;
  right: 20px;
  top: 20px;
}

.sidebar-closed #snav.mat-sidenav ~ .mat-drawer-content {
  margin-left: 50px !important;
}

mat-sidenav {
  padding-top: 0px;
}

.grand-admnin-sidenav-container {
  flex: 1;
}

.grand-admnin-sidenav-container {
  flex: 1 0 auto;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */
@media (min-width: 1650px) {
  .main-container.boxed {
    max-width: 1920px;
    margin: 0 auto;
  }
}
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */
@media (max-width: 1600px) {
  .main-container.boxed {
    max-width: 1300px;
    margin: 0 auto;
  }
}
/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (min-width: 1024px) {
  .card-group {
    padding: 0 15px;
    display: flex;
    flex-flow: row wrap;
  }
  .card-group .mat-card {
    margin: 15px 0px;
    flex: 1 0 0%;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  .card-group .mat-card:first-child {
    border: 0px;
  }
}
@media (max-width: 1023px) {
  .card-group {
    margin: 15px 0;
  }
  .card-group .mat-card {
    margin: 0 15px;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }
  .card-group .mat-card:first-child {
    border: 0px;
  }
}
/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
@media (min-width: 768px) {
  .navbar-header {
    width: 200px;
    flex-shrink: 0;
  }
  .navbar-header .navbar-brand {
    padding-top: 0px;
  }
}
/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@media (max-width: 767px) {
  .mat-toolbar-single-row {
    height: 64px !important;
  }
  .d-flex {
    display: block;
  }
  .no-block {
    display: flex;
  }
}
@font-face {
  font-family: "Larsseit";
  src: url("../fonts/Larsseit Regular/Larsseit Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-BlackIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-LightIt.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova/ProximaNova-ThinIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-WideMedium.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-WideMediumItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Agrandir-medium";
  src: url("../fonts/Agrandir-V3/Agrandir-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Agrandir-medium";
  src: url("../fonts/Agrandir-V3/Agrandir-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-Light.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Agrandir";
  src: url("../fonts/Agrandir-V3/Agrandir-LightItalic.otf") format("opentype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSansTTF/GoogleSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816/Brother1816-Medium.eot");
  src: local("Brother 1816 Medium"), local("Brother1816-Medium"), url("../fonts/Brother1816/Brother1816-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Brother1816/Brother1816-Medium.woff2") format("woff2"), url("../fonts/Brother1816/Brother1816-Medium.woff") format("woff"), url("../fonts/Brother1816/Brother1816-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
.ff-Helvetica-Neue {
  font-family: "Helvetica Neue", "Google Sans", sans-serif !important;
}
.ff-Agrandir {
  font-family: "Agrandir", "Google Sans", sans-serif !important;
}
.ff-Agrandir-medium {
  font-family: "Agrandir-medium", "Google Sans", sans-serif !important;
}
.ff-ProximaNova {
  font-family: "ProximaNova", "Google Sans", sans-serif !important;
}
.ff-Larsseit {
  font-family: "Larsseit", "Google Sans", sans-serif !important;
}
.ff-GoogleSans {
  font-family: "Google Sans", sans-serif !important;
}
.ff-Brother-1816 {
  font-family: "Brother 1816", "Google Sans", sans-serif !important;
}